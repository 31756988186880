'use strict'; // jshint ignore:line
import store from '@/store';
import axios from 'axios';
import request_apirid from '@/lib/request-apirid';
import Model from '@/lib/model';
import RequestError from '@/lib/request-error';
import util from '@/util';

class ForgotContact extends Model {

   static get attributes(){
      return {
         client_name: '',
         from_email: '',
         from_name: '',
         from_number: '',
         username: '',
         client_id: '',
         message: '',
         email: '',
         phone: '',
         page: window.location.href,
         referrer: document.referrer,
         session_info: JSON.stringify( store.state.server?.config?.session || null ),
         user_agent: navigator.userAgent,
      };
   }// /static get attributes()

   // async submit(){
   //    if( !this.username.trim() ){
   //       throw new Error( `Username is empty. Please enter a valid username.`,
   //          { cause: { code: 500, reason: 'no username', }, } );
   //    }
   //    return this.send();
   // }// /submit()

   async send(){
      const c_apirid = request_apirid();
      const c_search = new URLSearchParams({
         apirid: c_apirid,
      } ).toString();
      const c_data = new URLSearchParams( util.clone( this ) ).toString();
      const c_name = 'sendaccountrecoveremail2';
      const c_url = `/api/v1/${c_name}.wr?${c_search}`;

      try{
         const o_response = await axios({
            data: c_data,
            method: 'post',
            url: c_url,
         });

         const o_result = o_response.data;

         // The server received our request and provided us with a valid JSON
         // response. Now we check if the web1 server's response contains an error 
         // message.
         if (o_result.error) {
            // The server sent us an error message. Display the message to the user.
            const c_reason = JSON.stringify( o_result.error );
            throw new RequestError( `Forgot contact error: ${o_result.error.message}`,
               { cause: { code: 500, reason: c_reason, }, } );
         }
         
         // At this point the server has provided us with the data we asked for.
         // this.assign( o_result.result );
         return o_result;
      }
      catch( err ){
         // This can happend when web1 randomly returns a "no output" html error.
         if( err instanceof RequestError ){
            throw err;
         }

         throw new Error( `${c_name} Failed: ${err.message}`,
            { cause: { code: 500, reason: err.toString(), }, } );
      }
   }// /send()


}// /class ForgotContact()

export default ForgotContact;
