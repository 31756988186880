<template>
   <div class="forgot-contact">
      <div class="forgot-contact__main">
         <webstyle-logo class="forgot-contact__logo"></webstyle-logo>
         <vue-bootstrap-blocking-notifications></vue-bootstrap-blocking-notifications>

         <div v-if="!blocking_notification_shown">
            <div class="forgot-contact__success alert alert-success" v-if="success">
               Thank you! Your message was sent successfully. We will get back
               to you shortly.
            </div>
            <div class="forgot-contact__entry" v-if="!success">
               <p class="text-small">
                  If you've forgotten your username or password and need further
                  assistance, please fill out the form below and we'll get back to
                  you as soon as possible.
               </p>

               <form @submit.prevent="on_form_submit">

                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="text" id="input_from_name"
                           placeholder="Please enter your full name"
                           autofocus="autofocus" ref="autofocus"
                           v-model="forgot_contact.from_name"/>
                        
                        <label for="input_from_name">
                           What is your name?
                           <i class="fa-solid fa-asterisk text-danger"></i>
                        </label>
                     </div>
                  </div>

                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="text" id="input_from_email"
                           placeholder="Please enter your email address"
                           v-model="forgot_contact.from_email"/>
                        
                        <label for="input_from_email">
                           What is your email address?
                           <i class="fa-solid fa-asterisk text-danger"></i>
                        </label>
                     </div>
                  </div>


                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="hidden" id="input_email"
                           v-model="forgot_contact.email"/>
                        
                        <label for="input_email">
                           email
                           <i class="fa-solid fa-asterisk text-danger"></i>
                        </label>
                     </div>
                  </div>




                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="text" id="input_from_number"
                           placeholder="Please enter your contact number"
                           v-model="forgot_contact.from_number"/>
                        
                        <label for="input_from_number">
                           What is your phone number?
                           <i class="fa-solid fa-asterisk text-danger"></i>
                        </label>
                     </div>
                  </div>


                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="hidden" id="input_phone"
                           v-model="forgot_contact.phone"/>
                        
                        <label for="input_phone">
                           phone
                           <i class="fa-solid fa-asterisk text-danger"></i>
                        </label>
                     </div>
                  </div>


                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="text" id="input_client_id"
                           placeholder="Please enter your client ID"
                           v-model="forgot_contact.client_id"/>
                        
                        <label for="input_client_id">
                           What is your client ID?
                        </label>
                     </div>
                  </div>


                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="text" id="input_username"
                           placeholder="Please enter your username"
                           v-model="forgot_contact.username"/>
                        
                        <label for="input_username">
                           What is your username?
                        </label>
                     </div>
                  </div>



                  <div class="form-group">
                     <div class="form-floating">
                        <input class="form-control" type="text" id="input_client_name"
                           placeholder="Please enter your business/campaign name"
                           v-model="forgot_contact.client_name"/>
                        
                        <label for="input_client_name">
                           What is the name of your business/campaign?
                        </label>
                     </div>
                  </div>


                  <div class="form-group">
                     <div class="form-floating">
                        <textarea class="form-control" type="text" id="input_message"
                           placeholder="Please enter a brief message describing how we can help"
                           v-model="forgot_contact.message"></textarea>
                        
                        <label for="input_message">
                           How can we help?
                           <i class="fa-solid fa-asterisk text-danger"></i>
                        </label>
                     </div>
                  </div>


                  <div class="forgot-contact__btns">
                     <button class="forgot-contact__btn btn btn-primary" type="submit">
                        <i class="fa-solid fa-envelope"></i>
                        Send
                     </button>
                  </div>
               </form>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
// @ is an alias to /src
'use strict';
import {mapActions} from 'vuex';
import {mapGetters} from 'vuex';
import ForgotContact from '@/lib/forgot-contact';
import WebstyleLogo from '@/components/webstyle-logo';


export default {
   emits: [],

   components: {
      WebstyleLogo,
   },// /components{}

   data (){
      return {
         forgot_contact: new ForgotContact(),
         success: false,
      };
   },// /data()

   computed:{
      ...mapGetters([
         'webstyle',
      ]),
   }, // /computed{}

   methods: {
      async on_form_submit(){
         this.blocking_notification_show( 'wait', `Please wait...` );
         try{
            await this.forgot_contact.send();
            this.blocking_notification_clear();
            this.success = true;
         }
         catch( err ){
            this.blocking_notification_show( 'problem', err.message );
         }
      },// /on_form_submit()

      ...mapActions([
         'set_page_title',
      ]),
   },// methods{}

   mounted (){
      this.set_page_title( '' );

      /** 
       * Chrome will not autofocus and give an error like...
       * "Autofocus processing was blocked because a document's URL has a fragment '#/..'."
       * So we need to programmatically make sure this field is set focus to.
       */
      this.$refs.autofocus && this.$refs.autofocus.focus();
   },// /mounted()
};
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.forgot-contact__logo
{
   margin-bottom: 1em;
}

.forgot-contact__main
{
   background-color: var(--bs-body-bg);
   padding: 0.5em;
   border-radius: 0.1em;
   max-width: 30em;
   margin: auto;
}

.form-group
{
   margin-bottom: 1em;
}

.forgot-contact__btns
{
   display: flex;
   justify-content: flex-end;
}

.text-small
{
   font-size: 0.8em;
}

#input_message
{
   height: 8em;
}

/* Small only */
@media screen and (max-width: 767px) 
{
   .forgot-contact__btn
   {
      flex-grow: 1;
   }
}

/* Medium and up */
/*@media screen and (min-width: 768px) {}*/

/* Medium only */
/*@media screen and (min-width: 768px) and (max-width: 991px) {}*/

/* Large and up */
/*@media screen and (min-width: 992px) {}*/

/* Large only */
/*@media screen and (min-width: 992px) and (max-width: 1199px) {}*/

/* X-Large and up */
/*@media screen and (min-width: 1200px) {}*/
</style>
